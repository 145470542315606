import React from "react";

//import Layout from "../components/layout";
import SEO from "../components/seo";
import abductionIllustration from "../images/abduction-illustration.svg";

function NotFoundPage() {
  return (
    <>
      <SEO title="404: Not found" />
      <div>
        <img
          alt="Ghost getting abducted by aliens"
          className="block mx-auto w-1/2"
          src={abductionIllustration}
        />
        <h2 className="ax-bg-blue text-white text-2xl font-bold inline-block my-8 p-3">
          Page not found...
        </h2>
      </div>
    </>
  );
}

export default NotFoundPage;
